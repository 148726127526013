.layout {
    width: 99%;
    
    height: 99vh;
    text-align: center;

}



nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
nav a{
    
    /* border: 1px solid red; */
    vertical-align: middle;
    display: flex;
    align-items: center;
    padding: 1px;


}

/* -------------------- */

.profiles {
   
    width: 40%;
    display: flex;
    justify-content: space-around;
    /* flex-grow: 0.2; */

}

.usertile {
    background-color: aquamarine;
}

main {
    height: 93vh;
    overflow: auto;
}






/* ---------- */
footer {
    position: relative;
    bottom: 1px;

}

.home div {
    border: 2px solid darkslategrey;
    font-weight: 700;
    color:darkcyan;
    margin-top: 2px;
    padding: 5px;
}
.home div:hover{
    border-color: darkcyan;
}

.home div p {
    /* text-align: left; */
    font-weight: 700;
    /* border-bottom: 1px solid slategrey; */
    border-left: 1px solid slategrey;
    border-right: 1px solid slategrey;
    
}

.home div a:hover {
    background-color:darkcyan;
    color:black;
}