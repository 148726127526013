body {
  /* margin: 0  ; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  height: 99.6vh;
  background-color: rgb(37, 37, 37);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  color: black;
}

input {
  font-weight: 700;
  color: black;
}

textarea {
  color: black;
}

select {
  color: darkblue;

  option {
    color: black;
  }
}

* {
  margin: 0 auto;
  /* border: 1px solid blue; */

  color: beige;

  text-decoration: none;
  border-radius: 3px;

}



a {
  display: block;
}

input::placeholder {

  /* Firefox, Chrome, Opera */
  text-align: right;
}

/* ---------------------------*/

.layout {
  padding-bottom: 0;
}

footer {


  margin-top: 10px;
}

footer div {
  background-color: darkslategrey;
}

.nav {
  background-color: darkslategrey;
}

.nav * {
  background-color: darkslategrey;
}

.layout a {
  font-weight: 700;

}

/* ----------- */