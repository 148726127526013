
.commDiv{
    max-width: 46vw;
    margin: 10px auto ;
    background-color: rgba(0, 139, 139, 0.616);
    padding-bottom: 10px;
    /* border: 1px solid rgb(1, 228, 228,0.2); */
    border-radius: 5px;
}

  
.commBody{ 
    font-style: italic;
    margin: 0 auto;
    padding: 0 15px;
}

.cominfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px ;
}

.pcomments {
  padding: 5px;
  margin: 5px;
}

#commentform {
  padding: 5px;
  margin: 5px;
  button {
    margin: 5px;
  }
}

.pdTwo {
    margin-top: 10px;
}
/*  */

main .filters {
    margin-bottom: 10px ;
    margin-top: 10px;
}

/*  */

.profileDetails {
    border: 1px solid darkslategrey;
    background-color: darkslategray;
    margin: 10px;
    padding: 10px;
   
}

.profiles a:hover {
  color: cyan;
 
 
 
}

#ptile {
    padding: 1px;
}

#ptile a {
    background-color: rgba(0, 139, 139, 0.356) ;
    margin: 2px;
    border: 1px solid transparent;
  
  }

  #ptile p {
    color: lightgray;
  }

  #ptile a:hover {
    background-color: hsl(180, 26%, 20%);
    border-color: darkcyan;
    p{
        color: rgba(0, 255, 149, 0.459);
    }
  }

  .detailstile * {
    background-color: hsl(180, 26%, 20%);
    margin: 5px;
    padding: 5px;
    border: 1px solid transparent;
  }

.detailstile *:hover {
  border-color: cyan;
}

  .filterTypes *{
    margin: 2px;
    padding: 2px;
  }

  .filterTypes *:hover{
    color: darkslategray;
  }

  .collabnames {
    color: cyan
  }

  .login {
    background-color: darkslategray;
    margin: 10px;
    input {
      margin: 10px;
    }
    button {
      margin: 10px;
      font-weight: 600;
    }
  }

  /*  */

  .signup {
    background-color: darkslategray;
    margin: 10px;
    input{
      margin: 5px;

    }
    button {
      font-weight: 600;
    }
  }

  .account{
    padding: 5px;
    background-color: darkslategray;
     margin: 10px;
   a {
    font-size: 1rem;
    margin: 10px;
    padding: 10px;
    background-color: hsl(180, 26%, 20%);
    border: 0.1px solid transparent;
   }
   a:hover{
    color: cyan;
    border-color:  cyan;
    
   }
}

.userInfo {
  padding: 10px;
  background-color: darkslategray;
   margin: 10px;
   p{
    padding: 5px;
    margin: 5px;
    background-color: hsl(180, 26%, 20%); ;
   }
   button {
    margin: 5px;
   }
}

.delAcc {
  padding: 10px;
  background-color: darkslategray;
   margin: 10px;
}

.editB {
  border-left: 2px solid green;
  border-bottom: 2px solid green;
}

.delete {
  border-right: 2px solid cyan;
  border-bottom: 2px solid cyan;
}

.userForm *{
  background-color: rgb(135, 155, 155);
  margin: 5px;
  padding: 5px;
}